import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SignupService {
	
	signupUrl			:	any			=	'';
	postCodeUrl			:	any			=	'';
	SITE_API_URL		:	any			=	'';
	locationListUrl		:	any			=	'';
	data				:	any			=	{};
	

  /**define constructor */
  constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
		this.SITE_API_URL		=	environment.API_URL;
  }
  
	/** Function to submit signup form */
	submitSignupForm(data:any) {
		this.signupUrl		=	this.SITE_API_URL;
		return this.SharedService.getPost(this.signupUrl,data);
	}
	
	/** Function to get source location */
	getSourceLocation(sourcePostCode: any){
		this.locationListUrl		=	this.SITE_API_URL;
		this.data.method_name  = "locationArray";
		this.data.api_referrer = "web";
		this.data.post_code	  =	sourcePostCode;
		return this.SharedService.getPost(this.locationListUrl,this.data);
	}
  
  
  
}
